import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { properties } from "../../content/Properties"
import InnerPropertyPage from "../../components/molecules/InnerPropertyPage"

const slug = `13-ensign-way-wannanup-western-australia`

const post = properties.find(post => post.slug === slug) // Find the blog post by slug

const EnsignWayWannanupWesternAustralia = () => (
  <Layout>
    <Seo
      title="High Physical Support SDA Property in Wannanup – Accessible Living"
      description="Discover a High Physical Support SDA property in Wannanup offering fully accessible living with modern amenities designed for your comfort and independence. "
    />

    <InnerPropertyPage post={post} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default EnsignWayWannanupWesternAustralia
